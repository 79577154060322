import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import SectionName from 'common/SectionName';
import SectionTitle from 'common/SectionTitle';
import NumericList from 'components/NumericList';
import FaqAccordion from 'components/Faq/FaqAccordion';
import RelatedArticles from 'components/RelatedArticles';
import RelatedProducts from 'components/RelatedProducts';
import FeaturingProduct from 'components/FeaturingProduct';
import Video from 'components/Video';
import MediumBanner from 'components/MediumBanner';
import QuoteSection from 'components/QuoteSection';
import ReferencesSection from 'components/ReferencesSection';
import { DangerouslySetInnerHtml, Layout } from 'layout';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { ArticlePageProps } from './models';

import './ArticlePage.scss';

const ArticlePage: FC<ArticlePageProps> = ({
  pageContext: { areBreadcrumbsDisplayed, breadcrumb },
  data: {
    allArticle: {
      nodes: [article],
    },
    allSitePage: { nodes: breadcrumbNodes },
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(
    breadcrumb.crumbs,
    areBreadcrumbsDisplayed,
    breadcrumbNodes,
    pathname
  );

  return (
    <Layout className="article-page" seo={article} breadcrumbs={breadcrumbs}>
      <Container className="article-page__container">
        <Row>
          <Col>
            <div className="article-page__section-name">
              <SectionName text="Article" />
            </div>
            <SectionTitle text={article.name} customTitle={article.customTitle} />
          </Col>
        </Row>
        <Row>
          <Col>
            {article.video?.length ? (
              <Video
                videoId={article.video[0].properties.videoId}
                sectionName={article.video[0].properties.sectionName}
                title={article.video[0].properties.title}
              />
            ) : null}

            {article.imageBanner?.length ? (
              <MediumBanner
                image={article.imageBanner[0].properties.image}
                url={article.imageBanner[0].properties.url?.[0]}
                imageAlt={article.imageBanner[0].properties.imageAlt}
              />
            ) : null}

            {article.text?.length ? (
              <div className="article-page__text-container">
                <DangerouslySetInnerHtml className="article-page__text" html={article.text} />
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className="justify-content-lg-center">
          <Col sm="10" md="12" className="container">
            {article.featuringProduct?.length ? (
              <FeaturingProduct
                className="article-page__featuring-product"
                title={article.featuringProduct[0].properties.title}
                featuringProduct={article.featuringProduct[0].properties.featuringProduct}
              />
            ) : null}
            {article.numericList?.length ? (
              <NumericList
                items={article.numericList[0].properties.items}
                description={article.numericList[0].properties.description}
              />
            ) : null}
            {article.faqSection?.length ? (
              <FaqAccordion
                faqItems={article.faqSection[0].properties.faqItems}
                title={article.faqSection[0].properties.title}
                subtitle={article.faqSection[0].properties.subtitle}
                buttonRedirect={article.faqSection[0].properties.buttonRedirect}
                categoryId={article.faqSection[0].properties.categoryId}
              />
            ) : null}
            {article.solutionCenter?.length ? (
              <RelatedArticles
                className="article-page__solution-center"
                relatedArticlesGrid={article.solutionCenter[0].properties.relatedArticlesGrid}
                sectionName={article.solutionCenter[0].properties.sectionName}
                title={article.solutionCenter[0].properties.sectionName}
                buttonLink={article.solutionCenter[0].properties.buttonLink}
              />
            ) : null}
            {article.productFinder?.length ? (
              <RelatedProducts
                className="article-page__related-products"
                sectionLight={false}
                relatedProductList={article.productFinder[0].properties.relatedProductList}
                sectionName={article.productFinder[0].properties.sectionName}
                title={article.productFinder[0].properties.title}
              />
            ) : null}
          </Col>
          <Col sm="12">
            {article.quoteSection?.length ? (
              <QuoteSection
                topText={article.quoteSection[0].properties.topText}
                bottomText={article.quoteSection[0].properties.bottomText}
                image={article.quoteSection[0].properties.image}
                imageMobile={article.quoteSection[0].properties.imageMobile}
                imageAlt={article.quoteSection[0].properties.imageAlt}
                quote={article.quoteSection[0].properties.quote}
              />
            ) : null}

            {article.referencesSection?.length ? (
              <ReferencesSection
                topText={article.referencesSection[0].properties.topText}
                mainText={article.referencesSection[0].properties.mainText}
                image={article.referencesSection[0].properties.image}
                imageAlt={article.referencesSection[0].properties.imageAlt}
                references={article.referencesSection[0].properties.references}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query($id: String) {
    allArticle(filter: { id: { eq: $id } }) {
      nodes {
        ...SeoFragment
        id
        name
        customTitle
        video {
          ...VideoFragment
        }
        imageBanner {
          ...MediumBannerFragment
        }
        numericList {
          ...NumericListFragment
        }
        faqSection {
          ...FaqAccordionFragment
        }
        productFinder {
          ...RelatedProductsFragment
        }
        solutionCenter {
          ...RelatedArticlesFragment
        }
        featuringProduct {
          ...FeaturingProductFragment
        }
        text
        quoteSection {
          ...QuoteSectionFragment
        }
        referencesSection {
          ...ReferencesSectionFragment
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ArticlePage;
