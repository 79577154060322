import { graphql } from 'gatsby';
import React, { FC } from 'react';
import { NumericListProps } from './models';

import './NumericList.scss';

const NumericList: FC<NumericListProps> = ({ items, description: mainDescription }) => (
  <div className="numeric-list container">
    {mainDescription ? (
      <div
        className="numeric-list__description"
        dangerouslySetInnerHTML={{ __html: mainDescription }}
      />
    ) : null}
    <ol className="numeric-list__list">
      {items.map(({ properties: { title, description } }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li className="numeric-list__list-item" key={index}>
          <strong>{`${title}: `}</strong>
          <span>{description}</span>
        </li>
      ))}
    </ol>
  </div>
);

export const query = graphql`
  fragment NumericListFragment on NumericList {
    properties {
      description
      items {
        properties {
          title
          description
        }
      }
    }
  }
`;

export default NumericList;
