import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import Image from 'common/Image';

import { MediumBannerProps } from './models';

import './MediumBanner.scss';

export const MediumBanner: FC<MediumBannerProps> = ({ image, url, imageAlt }) => (
  <div data-testid="medium-banner" className="medium-banner">
    {url ? (
      <Link to={url.url}>
        <Image imageData={image} alt={imageAlt} className="medium-banner__image" />
      </Link>
    ) : (
      <Image imageData={image} alt={imageAlt} className="medium-banner__image" />
    )}
  </div>
);

export const query = graphql`
  fragment MediumBannerFragment on MediumBanner {
    properties {
      url {
        name
        url
      }
      imageAlt
      image {
        ...ImageFragment
      }
    }
  }
`;

export default MediumBanner;
