import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import useScreenRecognition from 'hooks/useScreenRecognition';
import Image from 'common/Image';

import { QuoteSectionProps } from './models';

import './QuoteSection.scss';

export const QuoteSection: FC<QuoteSectionProps> = ({
  image,
  imageMobile,
  imageAlt,
  topText,
  bottomText,
  quote,
}) => {
  const { isMobile } = useScreenRecognition();

  return (
    <div data-testid="quote-section" className="quote-section">
      {topText ? <DangerouslySetInnerHtml className="quote-section__text" html={topText} /> : null}

      {quote ? <q className="quote-section__quote">{quote}</q> : null}

      {bottomText ? (
        <DangerouslySetInnerHtml className="quote-section__text" html={bottomText} />
      ) : null}

      {isMobile && imageMobile ? (
        <Image imageData={imageMobile} alt={imageAlt} className="quote-section__image" />
      ) : (
        <Image imageData={image} alt={imageAlt} className="quote-section__image" />
      )}
    </div>
  );
};

export const query = graphql`
  fragment QuoteSectionFragment on QuoteSection {
    properties {
      topText
      bottomText
      quote
      imageAlt
      image {
        ...ImageFragment
      }
      imageMobile {
        ...ImageFragment
      }
    }
  }
`;

export default QuoteSection;
