import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { DangerouslySetInnerHtml } from 'layout';
import Image from 'common/Image';

import { ReferencesSectionProps } from './models';

import './ReferencesSection.scss';

export const ReferencesSection: FC<ReferencesSectionProps> = ({
  image,
  imageAlt,
  topText,
  mainText,
  references,
}) => (
  <div data-testid="references-section" className="references-section">
    {topText ? (
      <DangerouslySetInnerHtml className="references-section__text" html={topText} />
    ) : null}

    <div className="references-section__content">
      {image ? (
        <Image imageData={image} alt={imageAlt} className="references-section__image" />
      ) : null}

      {mainText ? (
        <DangerouslySetInnerHtml className="references-section__main-text" html={mainText} />
      ) : null}
    </div>

    {references ? (
      <DangerouslySetInnerHtml className="references-section__references" html={references} />
    ) : null}
  </div>
);

export const query = graphql`
  fragment ReferencesSectionFragment on ReferencesSection {
    properties {
      topText
      mainText
      references
      imageAlt
      image {
        ...ImageFragment
      }
    }
  }
`;

export default ReferencesSection;
