import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import SectionTitle from 'common/SectionTitle';
import Container from 'react-bootstrap/Container';
import ProductBox from '../ProductBox';
import { FeaturingProductProps } from './models';

import './FeaturingProduct.scss';

const FeaturingProduct: FC<FeaturingProductProps> = ({
  title,
  className,
  featuringProduct: [{ properties }],
}) => (
  <section data-testid="featuring-product" className={classNames('featuring-product', className)}>
    <Container>
      <Row>
        <Col lg={12}>
          <div className="featuring-product__top">
            <SectionTitle text={title} />
          </div>
        </Col>
        <Col>
          <div className="featuring-product__list">
            <ProductBox {...properties} counter={1} featuring />
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);

export const query = graphql`
  fragment FeaturingProductFragment on FeaturingProduct {
    properties {
      title
      featuringProduct {
        properties {
          ...ProductBoxFragment
        }
      }
    }
  }
`;
export default FeaturingProduct;
